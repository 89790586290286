/* eslint-disable react/sort-comp */

import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";

export const WordpressContext = createContext({});

class WordpressProvider extends Component {
  constructor() {
    super();

    this.state = {
      wordpressPosts: [],
      featuredWordpressPosts: []
    };

    axios.defaults.baseURL = `https://issues.itsallfluff.com/wp-json`;
    // axios.defaults.headers.common.Authorization = `Bearer ${process.env.GATSBY_Wordpress_TOKEN}`;
  }

  //

  request = (url, params = {}, method = `GET`) => {
    return new Promise((resolve, reject) => {
      const options = [`POST`, `PUT`, `PATCH`].includes(method.toUpperCase())
        ? { url, method, data: params }
        : { url, method, params };

      axios(options)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  //

  getFeaturedPosts = (params = {}) => {
    return new Promise((resolve, reject) => {
      this.request(`/wp/v2/posts?categories=9&_embed&per_page=3`, params)
        .then(posts => {
          resolve(posts);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  getPosts = (params = {}) => {
    return new Promise((resolve, reject) => {
      this.request(`/wp/v2/posts?_embed&per_page=9`, params)
        .then(posts => {
          console.log(posts);
          resolve(posts);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  insertTableRows = (docId, tableId, rows) => {
    return new Promise((resolve, reject) => {
      this.request(`/docs/${docId}/tables/${tableId}/rows`, rows, `POST`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  setFeaturedPosts = posts => {
    this.setState({ featuredWordpressPosts: posts });
  };

  setPosts = posts => {
    this.setState({ wordpressPosts: posts });
  };

  whoami() {
    return new Promise((resolve, reject) => {
      this.request(`/whoami`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  //

  render() {
    const { featuredWordpressPosts, wordpressPosts } = this.state;

    return (
      <WordpressContext.Provider
        value={{
          request: this.request,
          //
          getPosts: this.getPosts,
          getFeaturedPosts: this.getFeaturedPosts,
          getDoc: this.getDoc,
          getDocSections: this.getDocSections,
          getDocSection: this.getDocSection,
          getDocTables: this.getDocTables,
          getDocTable: this.getDocTable,
          getDocTableRows: this.getDocTableRows,
          insertTableRows: this.insertTableRows,
          setFeaturedPosts: this.setFeaturedPosts,
          setPosts: this.setPosts,
          whoami: this.whoami,
          //
          featuredWordpressPosts,
          wordpressPosts
        }}
      >
        {this.props.children}
      </WordpressContext.Provider>
    );
  }
}

WordpressProvider.propTypes = {
  children: PropTypes.shape({}).isRequired
};

export default WordpressProvider;
