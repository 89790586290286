import React, { Component } from "react";
import PropTypes from "prop-types";

import { getRandomIntByRange } from "~utils/helpers";

class RainCanvasComponent extends Component {
  state = {
    animating: [],
    imageCache: [],
    mounted: false
  };

  images = [
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` },
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` },
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` },
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` },
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` },
    { image: `💅` },
    { image: `💖` },
    { image: `🤷‍♀️` },
    { image: `🐰` }
  ];

  pauseThreshold = 6000;

  timeouts = [];

  componentDidMount() {
    this.setState({
      mounted: true
    });

    if (this.props.fast) {
      this.pauseThreshold = 3000;
    }

    this.bringTheRain();
  }

  componentWillUnmount() {
    if (this.timeouts.length) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  }

  //

  bringTheRain = () => {
    let upperRange = 1000;

    if (this.props.fast) {
      upperRange = 100;
    }

    this.timeouts.push(
      setTimeout(() => {
        this.animate();
      }, getRandomIntByRange(10, upperRange))
    );
  };

  animate = () => {
    const propsIndex = getRandomIntByRange(0, this.images.length - 1);
    const { animating } = this.state;

    if (animating.includes(propsIndex)) {
      this.bringTheRain();

      return;
    }

    animating.push(propsIndex);

    let rainImage = this.images[propsIndex];
    const { imageCache } = this.state;

    if (!this.state.imageCache.includes(rainImage)) {
      rainImage.imageOffsetLeft = getRandomIntByRange(-10, 80);
      rainImage.propsIndex = propsIndex;

      imageCache.push(rainImage);
    } else {
      imageCache[
        imageCache.indexOf(rainImage)
      ].imageOffsetLeft = getRandomIntByRange(-10, 80);
      rainImage = imageCache[imageCache.indexOf(rainImage)];
    }

    this.setState(
      {
        animating,
        imageCache
      },
      () => {
        this.timeouts.push(
          setTimeout(() => {
            animating.splice(animating.indexOf(propsIndex), 1);

            this.setState({
              animating
            });
          }, this.pauseThreshold)
        );
      }
    );

    this.bringTheRain();
  };

  //

  render() {
    const imageJSX = [];

    this.state.imageCache.forEach((image, index) => {
      const imageIndex = index;

      imageJSX.push(
        <div
          key={`image-${imageIndex}`}
          className={`rain-canvas__image absolute ${
            this.state.animating.includes(image.propsIndex) ? `rain` : ``
          }`}
          style={{
            left: `${image.imageOffsetLeft}vw`
          }}
        >
          <span className="f1" role="img" aria-label="raining emoji">
            {image.image}
          </span>
        </div>
      );
    });

    return (
      <aside
        className={`rain-canvas overflow-hidden ${
          this.props.fast ? `rain-canvas--fast` : ``
        } ${
          this.state.mounted ? `mounted` : ``
        } fixed w-screen h-screen top-0 left-0 z-50 pointer-events-none ${
          this.props.className
        }`}
      >
        {imageJSX}
      </aside>
    );
  }
}

const RainCanvas = props => <RainCanvasComponent {...props} />;

export default RainCanvas;

//
// PropTypes

RainCanvasComponent.propTypes = {
  className: PropTypes.string.isRequired,
  fast: PropTypes.bool.isRequired
};

RainCanvas.defaultProps = {
  className: ``,
  fast: false,
  images: []
};

RainCanvas.propTypes = {
  className: PropTypes.string,
  fast: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape({}))
};
