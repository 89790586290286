/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { AppContext } from "~context/AppContext";
import WordpressProvider, { WordpressContext } from "~context/WordpressContext";

import FormFooter from "~components/FormFooter";
import Layout from "~components/Layout";
import RainCanvas from "~components/RainCanvas";
import SEO from "~components/SEO";

//

import blurredImage from "~assets/images/banner_issues_blur_01.jpg";

class IssuesPageComponent extends Component {
  state = {
    featuredBGColor: `#231F20`
  };

  featuredImageRefs = [];

  componentDidMount() {
    const { appContext, wordpressContext } = this.props;

    appContext.setMenuActive(false);
    appContext.setNavText(`We've all got issues. We just publish ours.`);

    if (wordpressContext.featuredWordpressPosts.length === 0) {
      wordpressContext.getFeaturedPosts().then(posts => {
        posts.data.forEach(() => {
          this.featuredImageRefs.push(React.createRef());
        });

        wordpressContext.setFeaturedPosts(posts.data);

        this.setState({
          featuredBGColor: this.props.wordpressContext.featuredWordpressPosts[0]
            .acf.background_color
        });
      });
    }

    if (wordpressContext.wordpressPosts.length === 0) {
      wordpressContext.getPosts().then(posts => {
        wordpressContext.setPosts(posts.data);
      });
    }
  }

  //

  handleFeaturedPostHover = index => {
    const featuredImage = this.featuredImageRefs[index].current;

    this.featuredImageRefs.forEach(image => {
      image.current.classList.add(`opacity-0`);
      image.current.classList.remove(`opacity-100`);
    });

    featuredImage.classList.remove(`opacity-0`);

    this.setState({
      featuredBGColor: this.props.wordpressContext.featuredWordpressPosts[index]
        .acf.background_color
    });
  };

  //

  drawPlaceholderPosts = () => {
    return (
      <>
        <div className="issues-page__post w-1/3 xs:w-full bg-white">
          <img
            className="object-cover w-full"
            alt="Issues Post"
            src={blurredImage}
          />
          <div className="flex flex-col pt-4 pb-8 px-4">
            <h4 className="f4 font-medium text-dark-navy-blue mb-4">
              No Earth to Spare
            </h4>

            <p className="b1 font-light text-dark-navy-blue">
              We aren&apos;t so nice to the planet, but we can be.
            </p>
          </div>
        </div>

        <div className="issues-page__post w-1/3 xs:w-full bg-white">
          <img
            className="object-cover w-full"
            alt="Issues Post"
            src={blurredImage}
          />
          <div className="flex flex-col pt-4 pb-8 px-4">
            <h4 className="f4 font-medium text-dark-navy-blue mb-4">
              No Earth to Spare
            </h4>

            <p className="b1 font-light text-dark-navy-blue">
              We aren&apos;t so nice to the planet, but we can be.
            </p>
          </div>
        </div>

        <div className="issues-page__post w-1/3 xs:w-full bg-white">
          <img
            className="object-cover w-full"
            alt="Issues Post"
            src={blurredImage}
          />
          <div className="flex flex-col pt-4 pb-8 px-4">
            <h4 className="f4 font-medium text-dark-navy-blue mb-4">
              No Earth to Spare
            </h4>

            <p className="b1 font-light text-dark-navy-blue">
              We aren&apos;t so nice to the planet, but we can be.
            </p>
          </div>
        </div>
      </>
    );
  };

  //

  render() {
    const { appContext, wordpressContext, data } = this.props;

    return (
      <>
        <SEO pageTitle="Issues" pathName="/issues" />

        <RainCanvas
          className={`bg-purple ${
            wordpressContext.featuredWordpressPosts.length !== 0 ? `loaded` : ``
          }`}
          fast
        />

        <Layout className="issues-page w-full bg-sun-yellow gpu">
          <section
            className="issues-page__featured w-full h-full relative bg-purple-brown px-8 pt-8 pb-64 xs:pb-16"
            style={{ backgroundColor: this.state.featuredBGColor }}
          >
            {appContext.device === `mobile` && (
              <div className="absolute w-full h-full top-0 left-0">
                <Img
                  style={{ objectFit: `cover` }}
                  fluid={
                    data.markdownRemark.frontmatter.bannerMedia.src
                      .childImageSharp.fluid
                  }
                />
              </div>
            )}
            {wordpressContext.featuredWordpressPosts.length &&
              appContext.device !== `mobile` &&
              wordpressContext.featuredWordpressPosts.map(
                (featuredPost, index) => {
                  const featuredImageKey = `featuredImage-${index}`;

                  return (
                    <img
                      key={featuredImageKey}
                      ref={this.featuredImageRefs[index]}
                      className={`issues__featured--image mr-8 pb-16 ${
                        index === 0 ? `opacity-100` : `opacity-0  `
                      } h-full absolute top-0 right-0 z-0`}
                      src={
                        featuredPost._embedded[`wp:featuredmedia`][0].source_url
                      }
                      alt=""
                    />
                  );
                }
              )}

            {wordpressContext.featuredWordpressPosts.length &&
              wordpressContext.featuredWordpressPosts.map(
                (featuredPost, index) => {
                  const featuredPostKey = `featuredPost-${index}`;

                  return (
                    <a
                      key={featuredPostKey}
                      href={featuredPost.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="issues-page__featured--post w-3/4 xs:w-full flex flex-row xs:flex-col items-center xs:items-start mb-8 z-20 opacity-75 hover:opacity-100"
                      onMouseOver={() => this.handleFeaturedPostHover(index)}
                      onFocus={() => this.handleFeaturedPostHover(index)}
                    >
                      <h2
                        className="d2 font-light text-white xs:text-dark-navy-blue z-20"
                        dangerouslySetInnerHTML={{
                          __html: featuredPost.title.rendered
                        }}
                      ></h2>

                      {appContext.device !== `mobile` && (
                        <h4
                          className="f4 font-light text-white z-20 ml-8 xs:ml-0"
                          dangerouslySetInnerHTML={{
                            __html: featuredPost.acf.author
                          }}
                        ></h4>
                      )}
                    </a>
                  );
                }
              )}
          </section>

          <section className="issues-page__posts w-full relative flex flex-row items-center flex-wrap bg-white">
            {(wordpressContext.wordpressPosts.length &&
              wordpressContext.wordpressPosts.map((post, index) => {
                const postKey = `post-${index}`;

                return (
                  <a
                    href={post.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={postKey}
                    className="issues-page__post w-1/3 xs:w-full bg-white overflow-hidden"
                  >
                    <img
                      className="object-cover w-full"
                      alt="Issues Post"
                      src={post._embedded[`wp:featuredmedia`][0].source_url}
                    />
                    <div className="flex flex-col pt-4 pb-4 px-4">
                      <h4
                        className="f4 font-medium text-dark-navy-blue"
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered
                        }}
                      ></h4>

                      {/* <p
                        className="b1 font-light text-dark-navy-blue"
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered
                        }}
                      ></p> */}
                    </div>
                  </a>
                );
              })) ||
              this.drawPlaceholderPosts()}

            <div className="w-full flex justify-center">
              <a
                className="m-4 mb-24"
                href="https://issues.itsallfluff.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  type="button"
                  className="f4 bg-dark-navy-blue hover:bg-white border border-solid border-dark-navy-blue hover:text-dark-navy-blue text-white py-4 px-8 xs:py-2 xs:px-6"
                >
                  Read More
                </button>
              </a>
            </div>
          </section>
          <FormFooter headlineText="Headline. Goes here." />
        </Layout>
      </>
    );
  }
}

const IssuesPage = props => {
  return (
    <WordpressProvider>
      <WordpressContext.Consumer>
        {wordpressContext => (
          <AppContext.Consumer>
            {appContext => (
              <IssuesPageComponent
                appContext={appContext}
                wordpressContext={wordpressContext}
                {...props}
              />
            )}
          </AppContext.Consumer>
        )}
      </WordpressContext.Consumer>
    </WordpressProvider>
  );
};

export default IssuesPage;

export const query = graphql`
  query IssuesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
  }
`;
